<template>
	<div>
		<Row :gutter="10">

			<Col span="3" style="text-align: left">
			<Affix :offset-top="100">
				<Card class="menuOperation" style="width: 205px;">
					<p slot="title">节点列表</p>
					<Tree :data="pathData" @on-select-change="selectmachines" >
					</Tree>
				</Card>
			</Affix>
			</Col>

			<Col span="21" style="text-align: right">
				<Affix :offset-top="30">
			<Card class="menuOperation" style="height: 80px;width: 1444px;">
				<Button size="large" type="primary" @click="modal_createtask = true"
					style="position: absolute;left: 1%;">
					<Icon type="md-add-circle" /> 任务部署
				</Button>
				<Modal v-model="modal_createtask" title="创建任务">
					<Form ref="formtask" :label-width="80">

						<FormItem label="path" prop="项目">
							<Cascader :data="pathData_project" v-model="formtask"></Cascader>
						</FormItem>

						<FormItem label="task" prop="任务">
							<Input type="text" v-model="newtask" placeholder="task">
							</Input>
						</FormItem>

						<FormItem>
							<Button type="primary" @click="createtask()"
								style="position: absolute;left:20%;">提交</Button>
							<Button @click="handleReset()" style="position: absolute;left: 45%;">重置</Button>
						</FormItem>

					</Form>
				</Modal>

				<Button size="large" type="primary" @click="modal_deletetask = true"
					style="position: absolute;left: 15%;">
					<Icon type="md-close-circle" />删除任务
				</Button>

				<Modal v-model="modal_deletetask" title="删除任务">
					<Form :label-width="80">

						<FormItem label="TASK" prop="TASK">
							<Cascader change-on-select :data="pathData" v-model="formtask"></Cascader>
						</FormItem>


						<FormItem>
							<Button type="primary" @click="deletetask()"
								style="position: absolute;left:20%;">删除</Button>
							<Button @click="handleReset()" style="position: absolute;left: 45%;">重置</Button>
						</FormItem>

					</Form>
				</Modal>

				<Modal v-model="modal_updatetask" title="切换任务">
					<Form :label-width="80">

						<FormItem label="TASK" prop="TASK">
							<Cascader change-on-select :data="pathData" v-model="formtask"></Cascader>
						</FormItem>


						<FormItem>
							<Button type="primary" @click="updatetask()"
								style="position: absolute;left:20%;">提交</Button>
							<Button @click="handleReset()" style="position: absolute;left: 45%;">重置</Button>
						</FormItem>

					</Form>
				</Modal>



				<Input size="large" search enter-button placeholder="输入机器ID" @on-search="searchmachine"
					v-model="uuidforsearch" style="width: 400px;position: absolute;right: 1%;" />

			</Card  >
				</Affix>
			<br />
			<Drawer title="设备信息" :closable="false" v-model="isedit" width=70>

				<Divider>基础信息</Divider>
				<Table v-if="machineinfo_os.length > 0" border :columns="column_base" :data="machineinfo_os">
				</Table>
				<br />
				<Table v-if="machineinfo_os.length > 0" border :columns="column_os" :data="machineinfo_os"> </Table>
				<br />
				<Divider>硬盘信息</Divider>
				<Table border :columns="column_disk" :data="machineinfo_disk"> </Table>
				<br />
				<Divider>CPU信息</Divider>
				<Table border :columns="column_cpu" :data="machineinfo_cpu"> </Table>
				<br />
				<Divider>内存信息</Divider>
				<Table border :columns="column_memory" :data="machineinfo_memory"> </Table>
				<br />
				<Divider>网卡信息</Divider>
				<Table border :columns="column_nic" :data="machineinfo_nic"> </Table>
				<br />
			</Drawer>
<Affix :offset-top="170">
			<Card class="menuOperation" style="width: 1444px;">

				<Table v-if="machineData.length > 0" border ref="selection" :columns="columns" :data="machineData">
				</Table>
			</Card>
			</Affix>
			<br />
			<Page :total=this.pagesum show-total show-sizer @on-change="updatepage"
				@on-page-size-change="updatepagesize" style="text-align: right;" />
			</Col>
		</Row>
	</div>

</template>


<script>
	import {
		del,
		get,
		post,
		put
	} from "@/api/http";
	import BaseDrawer from "@/components/base/baseDrawer";
	import Notice from "@/components/baseNotice";
	import expandRow from './TableExpand.vue';


	export default {

		name: "Stree",
		data() {
			return {
				// gin_url: 'http://192.168.74.135:8082',
				uuidforsearch: '',
				isedit: false,
				modal_createtask: false,
				modal_deletetask: false,
                modal_updatetask:false,
				uuid_updatetask:'',
				formtask: [],
				newtask: '',
				column_base: [

					{
						title: '机器ID',
						width: 300,
						key: 'deviceuuid'
					},
					{
						title: '服务器型号',
						// width: 100,
						key: 'product',
						align: "center",
					},
					{
						title: '序列号',
						width: 250,
						key: 'serial_number',
						align: "center",
					},
					{
						title: '供应商',
						width: 120,
						key: 'vendor',
						align: "center",
					},
				],
				column_os: [{
						title: '系统版本',
						// width: 300,
						key: 'os_version',
						align: "center",
					},
					{
						title: '内核版本',
						// width: 00,
						key: 'kernel_version',
						align: "center",
					},
				],
				column_cpu: [{
						title: '型号',
						// width: 100
						key: 'product',
						align: "center",
					},
					{
						title: '序列号',
						width: 250,
						key: 'serial_number',
						align: "center",
					},
					{
						title: 'CPUID',
						width: 220,
						key: 'cpuid',
						align: "center",
					},
					{
						title: '插槽',
						width: 110,
						key: 'solt',
						align: "center",
					},
					{
						title: '核心数',
						width: 110,
						key: 'cores',
						align: "center",
					},
					{
						title: '线程数',
						width: 100,
						key: 'threads',
						align: "center",
					},
				],
				column_disk: [{
						title: '型号',
						// width: 300,
						key: 'product',
						align: "center",
					},
					{
						title: '序列号',
						width: 210,
						key: 'serial_number',
						align: "center",
					},
					{
						title: '块设备',
						width: 150,
						key: 'logicalname',
						align: "center",
					},
					{
						title: '挂载点',
						width: 150,
						key: 'mount_point',
						align: "center",
					},
					{
						title: '实跑任务',
						width: 200,
						key: 'task',
						align: "center",
						render: (h, {
							row
						}) => {
							return h('Badge', {
								props: {
									color: '#19be6b',
									text: row.task
								},
							});
						}

					},
					{
						title: '磁盘类型',
						width: 100,
						key: 'disk_media',
						align: "center",
					},
					{
						title: '容量',
						width: 150,
						key: '1000204886016',
						align: "center",
					},
				],
				column_memory: [{
						title: '型号',
						// width: 100,
						key: 'product',
						align: "center",
					},
					{
						title: '序列号',
						width: 250,
						key: 'serial_number',
						align: "center",
					},
					{
						title: '容量',
						width: 200,
						key: 'size',
						align: "center",
					},
					{
						title: '插槽',
						width: 150,
						key: 'solt',
						align: "center",
					},
					{
						title: '速度（MHz）',
						width: 200,
						key: 'clock',
						align: "center",
					},
				],
				column_nic: [{
						title: '型号',
						// width: 100,
						key: 'product',
						align: "center",
					},
					{
						title: '序列号',
						width: 250,
						key: 'serial_number',
						align: "center",
					},
					{
						title: '网卡名',
						width: 150,
						key: 'logicalname',
						align: "center",
					},
					{
						title: 'IP',
						width: 150,
						key: 'ipaddr',
						align: "center",
					},
					{
						title: '硬件地址',
						width: 190,
						key: 'macaddr',
						align: "center",
					},
					{
						title: '速度（Mbps）',
						width: 150,
						key: 'speed',
						align: "center",
					},
				],
				columns: [{
						type: 'expand',
						width: 50,
						fixed: 'left',
						render: (h, params) => {

							console.log(params.row.DeviceUUID)
							return h(expandRow, {
								props: {
									row: params.row
								}
							})
						}
					},
					{
						title: '设备ID',
						width: 300,
						key: 'DeviceUUID'
					},
					{
						title: '状态',
						width: 90,
						key: 'Online',
						align: "center",
						render: (h, {
							row
						}) => {
							let statusColor = {
								"online": ['在线', '#19be6b'],
								"offline": ['离线', '#ed4014']
							}
							return h('Badge', {
								props: {
									color: statusColor[row.Online][1],
									text: statusColor[row.Online][0]
								},
							});
						}
					},
					{
						title: '计划任务',
						width: 200,
						align: "center",
						key: 'SpecificTasks',
					},
					{
						title: '真实任务',
						width: 200,
						key: 'RealTasks',
						align: "center",
					},
					{
						title: '线路数',
						width: 100,
						key: 'LineCount',
						align: "center",
						sortable: true
					},
					{
						title: '流程状态',
						width: 130,
						key: 'State',
						align: "center",
						render: (h, {
							row
						}) => {
							let statusColor = {
								"binded": ['已绑定', '#19be6b'],
								"unbinded": ['未绑定', '#bebbb5'],
								"waitingTest": ['待测试', '#bebebe'],
								"testedFailed": ['测试未通过', '#ff0000'],
								"testedPassed": ['测试通过', '#19be6b'],
								"taskDeployed": ['任务已部署', '#19be6b'],
								"billing": ['计费中', '#19be6b'],
							}
							return h('Badge', {
								props: {
									color: statusColor[row.State][1],
									text: statusColor[row.State][0]
								},
							});
						}
					},

					{
						title: '标签',
						width: 200,
						key: 'Tags',
						align: "center",
					},
					{
						title: '操作',
						key: 'action',
						width: 163,
						fixed: 'right',
						align: 'center',
						render: (h, params) => {
							return h('div', [
								h('Button', {
									props: {
										type: 'primary',
										size: 'small'
									},
									style: {
										marginRight: '4px'
									},
									on: {
										click: () => {
											console.log(params.row.DeviceUUID)
											this.selectmachineinfo(params.row.DeviceUUID)
										}
									}
								}, '详情'),
								h('Button', {
									props: {
										type: 'primary',
										size: 'small'
									},
									style: {
										marginRight: '4px'
									},
									on: {
										click: () => {
											console.log(params.row.DeviceUUID)
											this.openupdatepage(params.row.DeviceUUID)
										}
									}
								}, '切换')
							]);
						}
					}
				],
				ruleInline: {},
				machineData: [],
				machineinfo_os: [],
				machineinfo_cpu: [],
				machineinfo_disk: [],
				machineinfo_memory: [],
				machineinfo_nic: [],
				pathData: [],
				pathData_project: [],
				searchData: {
					SpecificTask: '快手',
					project: '快手',
					dept: '运维部',
					pagesize: 10,
					page: 1
				},
				pagesum: 0,

			}

		},
		methods: {
			getstree() {
				// get(this.gin_url + "/stree/path")
				get("/stree/stree/path")
					.then((resp) => {

						var olbObj = resp
						var newresp = JSON.parse(JSON.stringify(olbObj))
						// this.pathData = resp
						this.pathData_project = newresp
						this.pathData = resp
						// console.log(olbObj)
						// console.log(newresp)

						// console.log(this.pathData_project)

						if (this.pathData_project != null) {
							for (var i = 0; i < this.pathData_project.length; i++) {
								if (this.pathData_project[i].children != null) {
									for (var j = 0; j < this.pathData_project[i].children.length; j++) {
										delete this.pathData_project[i].children[j].children
									}
								}
							}
						}



					}).catch((e) => {
						return e
					})
			},
			selectmachines(data) {
				this.searchData.page = 1
				// console.log(data[0].title)
				// console.log(this.pathData[0].children[0].children.length)
				if (this.pathData != null) {
					for (var i = 0; i < this.pathData.length; i++) {
						if (this.pathData[i].children != null) {
							for (var j = 0; j < this.pathData[i].children.length; j++) {
								if (this.pathData[i].children[j].children != null) {
									for (var k = 0; k < this.pathData[i].children[j].children.length; k++) {

										// console.log(this.pathData[i].children[j].children[k].title)

										if (this.pathData[i].children[j].children[k].title == data[0].title) {
											this.searchData.SpecificTask = this.pathData[i].children[j].children[k].title
											this.searchData.project = this.pathData[i].children[j].title
											this.searchData.dept = this.pathData[i].title

										}
									}
								}

							}
						}
					}
				}
				this.getMachines()
			},
			updatepage(data) {
				this.searchData.page = data
				this.getMachines()
			},
			updatepagesize(data) {
				this.searchData.pagesize = data
				this.getMachines()
			},
			getMachines() {

				// get(this.gin_url + "/stree/machine", this.searchData)
				get("/stree/stree/machine", this.searchData)
					.then((resp) => {
						this.machineData = resp.machine_info;
						console.log(this.machineData);
						this.pagesum = resp.pagesum
					}).catch((e) => {
						return e
					})
			},
			selectmachineinfo(data) {
				this.getMachineinfo(data)
				this.isedit = true


			},
			selectdiskinfo(data) {
				this.getMachineinfo(data)
			},
			getMachineinfo(data1) {

				// get(this.gin_url + "/stree/machine/" + data1)
				get("/stree/stree/machine/" + data1)
					.then((resp) => {
						this.machineinfo_os[0] = resp
						this.machineinfo_cpu = resp.pai_cpu
						this.machineinfo_disk = resp.pai_disk
						this.machineinfo_nic = resp.pai_nic
						this.machineinfo_memory = resp.pai_memory



					}).catch((e) => {
						return e
					})
			},
			createtask(data) {

				// var formdata = {}
				// formdata.dept = this.formtask[0]
				// formdata.project = this.formtask[1]
				// formdata.task = this.newtask
				// console.log(formdata)
				// post(this.gin_url + "/stree/path ", formdata)
				// 	.then((resp) => {

				// 		Notice.success(resp)
				// 		this.getstree()

				// 		this.formtask = []
				// 		this.newtask = ''
				// 		this.modal_createtask = false;
				// 	})
				// 	.catch((e) => {
				// 		return e
				// 	})

			},
			deletetask() {
				// var formdata = {}
				// formdata.dept = this.formtask[0]
				// formdata.project = this.formtask[1]
				// formdata.task = this.formtask[2]
				// console.log(formdata)
				// del(this.gin_url + "/stree/path ", formdata)
				// 	.then((resp) => {

				// 		if (resp.code == 1) {
				// 			Notice.error("删除失败，任务下存在主机")

				// 		} else {
				// 			Notice.success("删除成功")
				// 			this.getstree()
				// 		}

				// 		this.formtask = []
				// 		this.modal_deletetask = false;
				// 		// console.log(this.modal_deletetask, aaa)
				// 	})
				// 	.catch((e) => {
				// 		return e
				// 	})

			},
			openupdatepage(data){
				this.uuid_updatetask = data
				this.modal_updatetask = true;

			},
			updatetask(data) {
				// var formdata = {}
				// formdata.dept = this.formtask[0]
				// formdata.project = this.formtask[1]
				// formdata.task = this.formtask[2]
				// console.log(formdata)
				// put(this.gin_url + "/stree/task/"+this.uuid_updatetask, formdata)
				// 	.then((resp) => {

				// 		if (resp.code == 1) {
				// 			Notice.error("切换失败，后台错误")

				// 		} else {
				// 			Notice.success("切换成功")
				// 		}

				// 		this.formtask = []
				// 		this.modal_updatetask = false;
				// 		// console.log(this.modal_deletetask, aaa)
				// 	})
				// 	.catch((e) => {

				// 		this.modal_updatetask = false;
				// 	})

			},
			searchmachine() {

				if (this.uuidforsearch == '') {
					Notice.warning("请输入机器ID")
					return
				}

				// get(this.gin_url + "/stree/task/" + this.uuidforsearch)
				get("/stree/stree/task/" + this.uuidforsearch)
					.then((resp) => {


						if (this.pathData != null) {
							for (var i = 0; i < this.pathData.length; i++) {
								this.pathData[i].expand = false
								if (this.pathData[i].children != null) {
									for (var j = 0; j < this.pathData[i].children.length; j++) {
										this.pathData[i].children[j].expand = false
										if (this.pathData[i].children[j].children != null) {
											for (var k = 0; k < this.pathData[i].children[j].children
												.length; k++) {
												this.pathData[i].children[j].children[k].selected = false
											}
										}
									}
								}
							}
						}
						for (var t = 0; t < resp[0].path.length; t++) {
							if (this.pathData != null) {

								for (i = 0; i < this.pathData.length; i++) {
									console.log(this.pathData[i].title ,",",resp[0].path[t].group)
									if (this.pathData[i].title == resp[0].path[t].group) {
										this.pathData[i].expand = true
									}
									if (this.pathData[i].children != null) {
										for (j = 0; j < this.pathData[i].children.length; j++) {

											if (this.pathData[i].children[j].title == resp[0].path[t].project) {
												this.pathData[i].children[j].expand = true

											}

											if (this.pathData[i].children[j].children != null) {
												for (k = 0; k < this.pathData[i].children[j].children
													.length; k++) {

													if (this.pathData[i].children[j].children[k].title == resp[0].path[t]
														.app) {
														this.pathData[i].children[j].children[k].selected = true


													}
												}
											}

										}
									}
								}
							}
						}

					}).catch((e) => {
						return e
					})

				// get(this.gin_url + "/stree/machine/" + this.uuidforsearch)
				get("/stree/stree/machine/" + this.uuidforsearch)
					.then((resp) => {
						this.machineData = resp.machine_info;
						console.log(this.machineData);
						this.pagesum = 1

					}).catch((e) => {
						return e
					})


			},

			handleReset(data) {
				data.resetFields();
			},

		},
		created() {
			this.getstree();
			this.getMachines();
			// this.createtree();
			// this.data1[0].title = this.machineData.Node[0].NAME;


		}
	}
</script>


<style scoped>
	.expand-row {
		margin-bottom: 16px;
	}
</style>
